define('scientia-resourcebooker/helpers/customscript', ['exports'], function (exports) {
  exports['default'] = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      Ember.$(window).on('load', this.doLoadActions);
    },

    doLoadActions: function doLoadActions() {
      // your CSS and animation logic would go here
      // Ember.$('.rb-select-select.timeInput-select.timeInput-select-minutes option[value="15"]').remove();
      // Ember.$('.rb-select-select.timeInput-select.timeInput-select-minutes option[value="30"]').remove();
      // Ember.$('.rb-select-select.timeInput-select.timeInput-select-minutes option[value="45"]').remove();

      Ember.$('#ember1546').find("option").remove();
      Ember.$('#ember1546').css("background-color", "red");
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      //Ember.$(window).off('load', 'window', this.doLoadActions);
    }
  });
});